// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "team" ]

  connect() {
    console.log('User#contect')
  }

  addTeam(event) {
    console.log(`User#addTeam - path: ${event.params['path']}`)


    let that = this
    Rails.ajax({
      type: 'GET',
      url: event.params['path'],
      dataType: 'json',
      success: function (response) {
        setTimeout(
            function()
            {
              that.teamTarget.innerHTML = response.html
            }, 500);

      },
      error: function (response) {
        console.log('filter failed')
      }
    })

  }

}

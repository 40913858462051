// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "self" ]
  static values = { sendPath: String }

  connect() {

  }

  sendEmail(event) {
    console.log('Treatment#reject')

    let that = this
    Rails.ajax({
      type: 'GET',
      url: this.sendPathValue,
      dataType: 'json',
      success: function (response) {
        event.target.innerHTML = '<i class="fal fa-check fa-fw text-success"></i> Sent'
      },
      error: function (response) {
        console.log('filter failed')
      }
    })
  }

}

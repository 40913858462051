import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import { Application } from "stimulus"

const application = Application.start()

import * as ActiveStorage from "@rails/activestorage"
import * as bootstrap from "bootstrap/dist/js/bootstrap.bundle"
window.bootstrap = bootstrap

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("trix")
require("@rails/actiontext")

import "./controllers"

application.warnings = true
application.debug = false
window.Stimulus = application
// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "self" ]
  static values = { rejectUrl: String, resendCoverPath: String }

  connect() {

  }

  resendCoverRequest(event) {
    console.log('Treatment#reject')

    let that = this
    Rails.ajax({
      type: 'GET',
      url: this.resendCoverPathValue,
      dataType: 'json',
      success: function (response) {
        event.target.innerHTML = '<i class="fal fa-check fa-fw text-success"></i> Sent'
      },
      error: function (response) {
        console.log('filter failed')
      }
    })
  }

  reject() {
    console.log('Treatment#reject')

    let that = this
    Rails.ajax({
      type: 'GET',
      url: this.rejectUrlValue,
      dataType: 'json',
      success: function (response) {
        that.selfTarget.outerHTML = response.html
      },
      error: function (response) {
        console.log('filter failed')
      }
    })

  }

  back_to_start() {
    console.log('Treatment#reject')

    let that = this
    Rails.ajax({
      type: 'GET',
      url: this.rejectUrlValue + '?undo=yes',
      dataType: 'json',
      success: function (response) {
        that.selfTarget.outerHTML = response.html
      },
      error: function (response) {
        console.log('filter failed')
      }
    })

  }


}

// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "members", "practitioners" ]
  static values = { addUrl: String, removeUrl: String }

  connect() {
    console.log('Team#connect')
  }

  teamAdd(event) {
    let url = event.target.closest('button').value

    console.log(`Team#teamAdd url: ${url}`)

    let that = this
    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: function (response) {
        that.membersTarget.innerHTML = response.members
        that.practitionersTarget.innerHTML = response.users
      },
      error: function (response) {
        console.log('filter failed')
      }
    })
  }

  teamRemove(event) {
    let url = event.target.closest('button').value
    console.log(`Team#teamRemove: ${event.target.closest('button').value}`)

    let that = this
    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: function (response) {
        that.membersTarget.innerHTML = response.members
        that.practitionersTarget.innerHTML = response.users
      },
      error: function (response) {
        console.log('filter failed')
      }
    })
  }
}

// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "self" ]

  connect() {
    console.log('Memberships#connect')
  }

  update(event) {
    console.log(`Memberships#update path: ${event.target.closest('button').name}`)

    let that = this

    Rails.ajax({
      type: 'GET',
      url: event.target.closest('button').name,
      dataType: 'json',
      success: function (response) {
        that.selfTarget.outerHTML = response.html
      },
      error: function (response) {
        console.log('filter failed')
      }
    })
  }
}
